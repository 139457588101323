function Math() {  
  
  return (
    <div>
    <div className='content-container'>
      <h1>Can Kevin Math?</h1>
      <p>
      Counting is hard
      </p>
    </div>
  </div>
  );
}

export default Math;
